import React, {ReactElement, useEffect, useState} from 'react';

import '../App.css';
import iconAltitude from '../assets/wf_icons/altitude.png';
import iconBattery from '../assets/wf_icons/battery.png';
import iconBike from '../assets/wf_icons/bike.png';
import iconBodyBattery from '../assets/wf_icons/body_battery.png';
import iconCalendar from '../assets/wf_icons/calendar.png';
import iconCalories from '../assets/wf_icons/calories.png';
import iconDistance from '../assets/wf_icons/distance.png';
import iconFloorsClimbed from '../assets/wf_icons/floors_climbed.png';
import iconFootsteps from '../assets/wf_icons/footsteps.png';
import iconHeart from '../assets/wf_icons/heart.png';
import iconIntensityMinutes from '../assets/wf_icons/intensity_minutes.png';
import iconNotification from '../assets/wf_icons/notification.png';
import iconOxymeter from '../assets/wf_icons/oxymeter.png';
import iconRecoveryTime from '../assets/wf_icons/recovery_time.png';
import iconRun from '../assets/wf_icons/run.png';
import iconSolarInput from '../assets/wf_icons/solar_input.png';
import iconStress from '../assets/wf_icons/stress_level_3.png';
import iconSunset from '../assets/wf_icons/sunset.png';
import iconVo2Bike from '../assets/wf_icons/vo2bike.png';
import iconVo2Run from '../assets/wf_icons/vo2run.png';
import imgClearDay from '../assets/wf_weather/clear_day.png';
import {
  AltitudeDataField,
  BatteryDaysDataField,
  BatteryPercentageDataField,
  BodyBatteryDataField,
  CalendarEventDataField,
  CaloriesDataField,
  CurrentTemperatureDataField,
  DataFieldType,
  DistanceWalkedDataField,
  FloorsClimbedDataField,
  HeartRateDataField,
  IDataField,
  IntensityMinutesDataField,
  MinMaxTemperatureDataField,
  MonthDataField,
  MonthDayDataField,
  NotificationCountDataField,
  PulseOxDataField,
  RecoveryTimeDataField,
  StepsDataField,
  StressDataField,
  SunriseSunsetDataField,
  Vo2MaxBikeDataField,
  Vo2MaxRunDataField,
  WeekDayDataField,
  WeeklyBikeDistanceDataField,
  WeeklyRunDistanceDataField,
} from './DataFields';
import {formatTimeH} from './Formatting';
import './css/NeuPro.css';

const P1 = 10;
const P2 = 20;

const ARCS = [
  [-90 + P1, -P2],
  [P2, 90 - P1],
  [90 + P1, 180 - P2],
  [180 + P2, 270 - P1],
];

export type SecondsIndicatorType = 'analog' | 'right_field' | 'off';

export type NeuProWatchFaceProps = {
  addAllSecondIndicatorsToDom?: boolean;
  dataFieldBottom?: DataFieldType;
  dataFieldLeft?: DataFieldType;
  dataFieldRight?: DataFieldType;
  dataFieldTop?: DataFieldType;
  hoursOverride?: number;
  langOverride?: string;
  minutesOverride?: number;
  secondsIndicator?: SecondsIndicatorType;
};

export default function NeuProWatchFace({
  addAllSecondIndicatorsToDom = false,
  dataFieldBottom,
  dataFieldLeft,
  dataFieldRight,
  dataFieldTop,
  hoursOverride,
  langOverride,
  minutesOverride,
  secondsIndicator = 'analog',
}: NeuProWatchFaceProps): ReactElement {
  // TODO: check system language too
  const isRtl = langOverride ? ['he', 'ar'].includes(langOverride) : false;

  return (
    // Note that the colors will cascade down from here
    <div
      className="Watch-face Watch-face-bg-fill Watch-face-bg-bgColor"
      style={{position: 'relative'}}>
      <Bg
        addAllSecondIndicatorsToDom={addAllSecondIndicatorsToDom}
        secondsIndicator={secondsIndicator}
      />
      <Time hoursOverride={hoursOverride} minutesOverride={minutesOverride} />
      <DataField
        dataFieldType={dataFieldLeft ?? 'battery_days'}
        isRtl={isRtl}
        langOverride={langOverride}
        layout="vertical"
        xPct={10}
        yPct={50}
      />
      {secondsIndicator === 'right_field' || addAllSecondIndicatorsToDom ? (
        <div style={{opacity: secondsIndicator === 'right_field' ? 1 : 0}}>
          <SecondsDigitalIndicator xPct={90} yPct={50} />
        </div>
      ) : null}
      {secondsIndicator !== 'right_field' ? (
        <DataField
          dataFieldType={dataFieldRight ?? 'weekday_monthday'}
          isRtl={isRtl}
          langOverride={langOverride}
          layout="vertical"
          xPct={90}
          yPct={50}
        />
      ) : null}
      <DataField
        dataFieldType={dataFieldTop ?? 'current_temperature'}
        isRtl={isRtl}
        langOverride={langOverride}
        layout="horizontal"
        xPct={50}
        yPct={22}
      />
      <DataField
        dataFieldType={dataFieldBottom ?? 'heart_rate'}
        isRtl={isRtl}
        langOverride={langOverride}
        layout="horizontal"
        xPct={50}
        yPct={78}
      />
    </div>
  );
}

type BgProps = {
  addAllSecondIndicatorsToDom: boolean;
  secondsIndicator: SecondsIndicatorType;
};

function Bg({
  addAllSecondIndicatorsToDom,
  secondsIndicator,
}: BgProps): ReactElement {
  return (
    <svg
      style={{
        height: '100%',
        left: '0%',
        position: 'absolute',
        right: '0%',
        width: '100%',
      }}
      viewBox="0 0 100 100">
      <clipPath id="clipCircle">
        <circle cx="50" cy="50" r="50" />
      </clipPath>
      <g className="Neu-pro-analog-seconds">
        <circle
          className="Watch-face-accent-fill"
          cx="50%"
          cy="5%"
          r="0.8%"
          strokeWidth="3cqw"
        />
        <circle
          className="Watch-face-accent-fill"
          cx="50%"
          cy="95%"
          r="0.8%"
          strokeWidth="3cqw"
        />
        {ARCS.map((a, i) => (
          <Arc
            className="Watch-face-accent-stroke"
            ea={a[1]}
            key={i}
            sa={a[0]}
            strokeWidth="1%"
          />
        ))}
      </g>
      {secondsIndicator === 'analog' || addAllSecondIndicatorsToDom ? (
        <g opacity={secondsIndicator === 'analog' ? 1 : 0}>
          <SecondsAnalogIndicator addAllSecondIndicatorsToDom />
        </g>
      ) : null}
    </svg>
  );
}

type SecondsAnalogIndicatorProps = {
  addAllSecondIndicatorsToDom: boolean;
};

function SecondsAnalogIndicator({
  addAllSecondIndicatorsToDom,
}: SecondsAnalogIndicatorProps): ReactElement {
  const initialTime = new Date();
  const [seconds, setSeconds] = useState(initialTime.getSeconds());

  useEffect(() => {
    const t = setInterval(() => {
      const time = new Date();
      setSeconds(time.getSeconds());
    }, 1000);
    return () => {
      clearInterval(t);
    };
  }, []);

  const aDeg = (seconds * 360) / 60 - 90;
  const aRad = (aDeg * Math.PI) / 180;

  const r1 = 45;
  const r2 = 50;
  const rDot = 50;

  const cx = 50;
  const cy = 50;

  const sx = r1 * Math.cos(aRad) + cx;
  const sy = r1 * Math.sin(aRad) + cy;
  const ex = r2 * Math.cos(aRad) + cx;
  const ey = r2 * Math.sin(aRad) + cy;
  const dotX = rDot * Math.cos(aRad) + cx;
  const dotY = rDot * Math.sin(aRad) + cy;

  let lineColorClass = 'Watch-face-time-stroke';
  let fillColorClass = 'Watch-face-time-fill';
  let ba = 13;
  let ba2 = 11;
  let lineDot = null;

  if ((aDeg > -ba && aDeg < ba) || (aDeg > 180 - ba && aDeg < 180 + ba)) {
    lineDot = (
      <circle
        className={'Watch-face-time-fill'}
        clipPath="url(#clipCircle)"
        cx={dotX}
        cy={dotY}
        r="1%"
        strokeWidth="3cqw"
      />
    );

    if ((aDeg > -ba2 && aDeg < ba2) || (aDeg > 180 - ba2 && aDeg < 180 + ba2)) {
      lineColorClass = 'Watch-face-time-stroke-blend-0_33';
      fillColorClass = 'Watch-face-time-fill-blend-0_33';
    } else {
      lineColorClass = 'Watch-face-time-stroke-blend-0_67';
      fillColorClass = 'Watch-face-time-fill-blend-0_67';
    }
  }

  return (
    <g className="Neu-pro-analog-seconds">
      {ARCS.map((a, i) => {
        if (aDeg < a[0] && !addAllSecondIndicatorsToDom) {
          return null;
        }
        return (
          <g key={i} opacity={aDeg < a[0] ? 0 : 1}>
            <Arc
              className="Watch-face-time-stroke"
              ea={Math.min(aDeg, a[1])}
              sa={a[0]}
              strokeWidth="2%"
            />
          </g>
        );
      })}
      {(aDeg > -90 || addAllSecondIndicatorsToDom) && (
        <g opacity={aDeg > -90 ? 1 : 0}>
          <circle
            className="Watch-face-time-fill"
            cx="50%"
            cy="5%"
            r="1.2%"
            strokeWidth="3cqw"
          />
        </g>
      )}
      {(aDeg > 90 || addAllSecondIndicatorsToDom) && (
        <g opacity={aDeg > 90 ? 1 : 0}>
          <circle
            className="Watch-face-time-fill"
            cx="50%"
            cy="95%"
            opacity={aDeg > 90 ? 1 : 0}
            r="1.2%"
            strokeWidth="3cqw"
          />
        </g>
      )}
      <line
        className={`${lineColorClass}`}
        strokeWidth="2%"
        x1={sx}
        x2={ex}
        y1={sy}
        y2={ey}
      />
      {lineDot}
      <circle
        className={`${fillColorClass}`}
        cx={sx}
        cy={sy}
        r="1%"
        strokeWidth="3cqw"
      />
    </g>
  );
}

type SecondsDigitalIndicatorProps = {
  xPct: number;
  yPct: number;
};

function SecondsDigitalIndicator({
  xPct,
  yPct,
}: SecondsDigitalIndicatorProps): ReactElement {
  const initialTime = new Date();
  const [seconds, setSeconds] = useState(initialTime.getSeconds());

  useEffect(() => {
    const t = setInterval(() => {
      const time = new Date();
      setSeconds(time.getSeconds());
    }, 1000);
    return () => {
      clearInterval(t);
    };
  }, []);

  const sPct = 10;

  return (
    <div
      className="Neu-pro-digital-seconds"
      style={{
        alignContent: 'center',
        alignItems: 'center',
        color: 'white',
        display: 'flex',
        height: `${sPct * 2}%`,
        justifyContent: 'center',
        left: `${xPct - sPct}%`,
        position: 'absolute',
        top: `${yPct - sPct}%`,
        width: `${sPct * 2}%`,
      }}>
      <div className="Seconds-text Watch-face-time-color">
        {seconds.toFixed(0).padStart(2, '0')}
      </div>
    </div>
  );
}

type TimeProps = {
  hoursOverride?: number;
  minutesOverride?: number;
};

function Time({hoursOverride, minutesOverride}: TimeProps): ReactElement {
  // TODO: I can make the overridden time move forward as well, but do I want to?

  const initialTime = new Date();
  const [hours, setHours] = useState(initialTime.getHours());
  const [minutes, setMinutes] = useState(initialTime.getMinutes());

  // TODO: reimplement
  useEffect(() => {
    const t = setInterval(() => {
      const time = new Date();
      setHours(time.getHours());
      setMinutes(time.getMinutes());
    }, 1000);
    return () => {
      clearInterval(t);
    };
  }, []);

  // TODO: format time properly

  return (
    <div
      style={{
        alignSelf: 'center',
        color: 'white',
        left: '0%',
        position: 'absolute',
        right: '0%',
      }}>
      <div
        className="Time-text Watch-face-time-color"
        style={{alignContent: 'center'}}>
        {formatTimeH(hoursOverride ?? hours)}
        <div className="Time-delimiter Watch-face-accent-bgColor" />
        {(minutesOverride ?? minutes).toFixed(0).padStart(2, '0')}
      </div>
    </div>
  );
}

type DataFieldProps = {
  dataFieldType: DataFieldType;
  isRtl: boolean;
  langOverride?: string;
  layout: 'vertical' | 'horizontal';
  xPct: number;
  yPct: number;
};

function DataField({
  dataFieldType,
  isRtl,
  langOverride,
  layout,
  xPct,
  yPct,
}: DataFieldProps): ReactElement {
  const sPct = 10;

  const length = layout === 'horizontal' ? 'long' : 'short';

  // TODO: re-render periodically so that stuff like dates updates correctly?

  let title: {type: 'string'; value: string} | {type: 'icon'; url: string};
  let dataField: IDataField;
  const iconTitleClassName = `Neu-Pro-data-icon-title-${dataFieldType}`;
  const textTitleClassName = `Neu-Pro-data-text-title-${dataFieldType}`;

  switch (dataFieldType) {
    case 'month_monthday':
      title = {
        type: 'string',
        value: new MonthDataField().getValueAsText(length, langOverride),
      };
      dataField = new MonthDayDataField();
      break;
    case 'weekday_monthday':
      title = {
        type: 'string',
        value: new WeekDayDataField().getValueAsText(length, langOverride),
      };
      dataField = new MonthDayDataField();
      break;
    case 'battery_days':
      title = {type: 'icon', url: iconBattery};
      dataField = new BatteryDaysDataField();
      break;
    case 'battery_percentage':
      title = {type: 'icon', url: iconBattery};
      dataField = new BatteryPercentageDataField();
      break;
    case 'solar_input':
      title = {type: 'icon', url: iconSolarInput};
      dataField = new HeartRateDataField();
      break;
    case 'heart_rate':
      title = {type: 'icon', url: iconHeart};
      dataField = new HeartRateDataField();
      break;
    case 'steps':
      title = {type: 'icon', url: iconFootsteps};
      dataField = new StepsDataField();
      break;
    case 'distance_walked':
      title = {type: 'icon', url: iconDistance};
      dataField = new DistanceWalkedDataField();
      break;
    case 'calories':
      title = {type: 'icon', url: iconCalories};
      dataField = new CaloriesDataField();
      break;
    case 'floors_climbed':
      title = {type: 'icon', url: iconFloorsClimbed};
      dataField = new FloorsClimbedDataField();
      break;
    case 'current_temperature':
      title = {type: 'icon', url: imgClearDay};
      dataField = new CurrentTemperatureDataField();
      break;
    case 'min_max_temperature':
      title = {type: 'icon', url: imgClearDay};
      dataField = new MinMaxTemperatureDataField();
      break;
    case 'sunrise_sunset':
      title = {type: 'icon', url: iconSunset};
      dataField = new SunriseSunsetDataField();
      break;
    case 'altitude':
      title = {type: 'icon', url: iconAltitude};
      dataField = new AltitudeDataField();
      break;
    case 'notification_count':
      title = {type: 'icon', url: iconNotification};
      dataField = new NotificationCountDataField();
      break;
    case 'calendar_event':
      title = {type: 'icon', url: iconCalendar};
      dataField = new CalendarEventDataField();
      break;
    case 'body_battery':
      title = {type: 'icon', url: iconBodyBattery};
      dataField = new BodyBatteryDataField();
      break;
    case 'stress':
      title = {type: 'icon', url: iconStress};
      dataField = new StressDataField();
      break;
    case 'intensity_minutes':
      title = {type: 'icon', url: iconIntensityMinutes};
      dataField = new IntensityMinutesDataField();
      break;
    case 'recovery_time':
      title = {type: 'icon', url: iconRecoveryTime};
      dataField = new RecoveryTimeDataField();
      break;
    case 'weekly_run_distance':
      title = {type: 'icon', url: iconRun};
      dataField = new WeeklyRunDistanceDataField();
      break;
    case 'weekly_bike_distance':
      title = {type: 'icon', url: iconBike};
      dataField = new WeeklyBikeDistanceDataField();
      break;
    case 'vo2_max_run':
      title = {type: 'icon', url: iconVo2Run};
      dataField = new Vo2MaxRunDataField();
      break;
    case 'vo2_max_bike':
      title = {type: 'icon', url: iconVo2Bike};
      dataField = new Vo2MaxBikeDataField();
      break;
    case 'pulse_ox':
      title = {type: 'icon', url: iconOxymeter};
      dataField = new PulseOxDataField();
      break;
    default:
      throw new Error('Not implemented');
  }

  return (
    <div
      className="Neu-Pro-data-field"
      style={{
        alignContent: 'center',
        alignItems: 'center',
        color: 'white',
        direction: isRtl ? 'rtl' : 'ltr',
        display: 'flex',
        flexDirection: layout === 'vertical' ? 'column' : 'row',
        gap: layout === 'vertical' ? 3 : 6,
        height: `${sPct * 2}%`,
        justifyContent: 'center',
        left: `${xPct - sPct}%`,
        position: 'absolute',
        top: `${yPct - sPct}%`,
        width: `${sPct * 2}%`,
      }}>
      <div className={`Neu-Pro-data-title-${dataFieldType}`}>
        {title.type === 'icon' ? (
          <div
            className={`Data-icon Data-icon-${layout} Data-icon-${dataFieldType} Neu-Pro-data-icon-color Data-fields-allIcons-bgColor`}
            style={{
              maskImage: `url(${title.url})`,
            }}
          />
        ) : (
          <div className={`Data-title-${length} Neu-Pro-data-title-color`}>
            {title.value}
          </div>
        )}
      </div>
      {/* <div className="Data-text Watch-face-time-color"> */}
      <div className={`Data-text Watch-face-time-color`} lang="en">
        {dataField.getValueAsText(length, langOverride)}
      </div>
    </div>
  );
}

type ArcProps = {
  className: string;
  ea: number;
  opacity?: number | undefined;
  sa: number;
  strokeWidth: string;
};

function Arc({className, ea, opacity, sa, strokeWidth}: ArcProps) {
  const k = Math.PI / 180;
  const r = 45;
  const cx = 50;
  const cy = 50;
  const sx = r * Math.cos(sa * k) + cx;
  const sy = r * Math.sin(sa * k) + cy;
  const ex = r * Math.cos(ea * k) + cx;
  const ey = r * Math.sin(ea * k) + cy;
  return (
    <path
      className={`${className}`}
      d={`
        M ${sx} ${sy}
        A ${r} ${r} 0 0 1 ${ex} ${ey}
      `}
      opacity={opacity}
      strokeWidth={strokeWidth}
    />
  );
}
