import React, {
  ReactElement,
  ReactNode,
  createContext,
  useMemo,
  useState,
} from 'react';

export type ConnectIqVisitContextType = {
  hasBeenLogged: boolean;
  navButtonClickedTimes: number;
  reportNavButtonClick: () => void;
};

export const ConnectIqVisitContext = createContext<ConnectIqVisitContextType>({
  hasBeenLogged: false,
  navButtonClickedTimes: 0,
  reportNavButtonClick: () => {
    console.error('No ConnectIqVisitContext.Provider above');
    throw new Error('No ConnectIqVisitContext.Provider above');
  },
});

type Props = {
  children: ReactNode;
};

export function ConnectIqVisitContextProvider({children}: Props): ReactElement {
  const [navButtonClickedTimes, setNavButtonClickedTimes] = useState(0);

  const ctxValue = useMemo((): ConnectIqVisitContextType => {
    return {
      hasBeenLogged: navButtonClickedTimes > 0,
      navButtonClickedTimes,
      reportNavButtonClick: () => {
        setNavButtonClickedTimes(prev => prev + 1);
      },
    };
  }, [navButtonClickedTimes]);

  return (
    <ConnectIqVisitContext.Provider value={ctxValue}>
      {children}
    </ConnectIqVisitContext.Provider>
  );
}
