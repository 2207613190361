import {getAnalytics, logEvent} from '@firebase/analytics';

export function logAnalyticsEvent(
  eventName: string,
  data?: {[k: string]: any},
) {
  try {
    logEvent(getAnalytics(), eventName, data);
  } catch (e) {
    try {
      console.error(`logEvent failed (${eventName})`, e);
    } catch (e2) {}
  }
}
