import {useEffect} from 'react';

import {logAnalyticsEvent} from './AnalyticsUtils';

export default function useCloudFunctionsWarmUp() {
  useEffect(() => {
    const impl = async () => {
      try {
        const response = await fetch(
          'https://us-central1-connectiq-441119.cloudfunctions.net/app/warm_up',
        );
        // console.log('Warm-up successful:', response.text);
        logAnalyticsEvent('cf_warm_up_successful');
      } catch (e) {
        // console.error('Warm-up failed:', e);
        logAnalyticsEvent('cf_warm_up_failed');
      }
    };
    impl();
  }, []);
}
