import React, {ReactElement} from 'react';

import DownloadParagraph from '../components/DownloadParagraph';
import UserReview from '../components/UserReview';
import ViewTracker from '../components/ViewTracker';
import NeuProWatchFace from '../watch_face/NeuPro';
import './css/SectionIntroduce.css';

type Props = {};

export default function SectionIntroduce({}: Props): ReactElement {
  return (
    <ViewTracker sectionId="section_intro">
      <section className="section-with-image">
        <div className="section-title-cell">
          <h1>
            Meet <b>Neu Pro</b>,
          </h1>
          <h3>stylish and functional watch face for Garmin</h3>
        </div>
        <div className="section-image-cell">
          <div
            className="Watch-container section-image-clip"
            style={{position: 'relative'}}>
            <div className="Watch-image Abs-fill-parent" />
            <div className="Abs-fill-parent Introduce-img-wf-placeholder">
              <NeuProWatchFace />
            </div>
          </div>
        </div>
        <div className="section-text-cell">
          <DownloadParagraph buttonElementName="introduce_section_button" />
          <UserReview
            author="Naara"
            source="connect_iq"
            text="Love it! Exact blend of minimal style and usefulness I was looking for"
            // text="Very clean, much wow 👌🏻😉"
          />
          {/* <UserReview
        author="Konrad"
        source="connect_iq"
        text="Very clean, much wow 👌🏻😉"
        // text="Very clean, much wow 👌🏻😉"
      /> */}
        </div>
      </section>
    </ViewTracker>
  );
}
