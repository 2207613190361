export function nullthrows<T>(
  val: T,
  msg?: string | undefined,
): NonNullable<T> {
  if (val == null) {
    throw new Error(msg ? 'Unexpected null: ' + msg : 'Unexpected null');
  }

  return val;
}

const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function isDev(): boolean {
  return development;
}
