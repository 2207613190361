import React, {
  ReactElement,
  ReactNode,
  createContext,
  useMemo,
  useState,
} from 'react';

export type ConsentContextType = {
  adTrackingAllowed: boolean;
  analyticsAllowed: boolean;
};

export const ConsentContext = createContext<ConsentContextType>({
  adTrackingAllowed: false,
  analyticsAllowed: false,
});

export type ConsentSetterContextType = {
  setConsentSettings(value: ConsentContextType): void;
};

export const ConsentSetterContext = createContext<ConsentSetterContextType>({
  setConsentSettings(value: ConsentContextType) {
    console.error('No ConsentSetterContext.Provider above');
    throw new Error('No ConsentSetterContext.Provider above');
  },
});

type Props = {
  children: ReactNode;
};

export function ConsentContextProvider({children}: Props): ReactElement {
  const [consentSettings, setConsentSettings] = useState<ConsentContextType>({
    adTrackingAllowed: false,
    analyticsAllowed: false,
  });

  const setterCtxValue = useMemo(
    (): ConsentSetterContextType => ({setConsentSettings}),
    [],
  );

  return (
    <ConsentSetterContext.Provider value={setterCtxValue}>
      <ConsentContext.Provider value={consentSettings}>
        {children}
      </ConsentContext.Provider>
    </ConsentSetterContext.Provider>
  );
}
