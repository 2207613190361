import React, {ReactElement, useMemo} from 'react';

import {ReactRef, useGSAP} from '@gsap/react';
import {gsap} from 'gsap';

export type MetaTimelineDirection = 'forward' | 'backward';

export type GsapToggleAction =
  | 'play'
  | 'pause'
  | 'resume'
  | 'reset'
  | 'restart'
  | 'complete'
  | 'reverse'
  | 'none';
export type GsapToggleActions =
  `${GsapToggleAction} ${GsapToggleAction} ${GsapToggleAction} ${GsapToggleAction}`;

export type MetaTimelinePoint = {
  handler: (dir: MetaTimelineDirection) => void;
  position: number;
  setupTimeline: (dir: MetaTimelineDirection, tl: gsap.core.Timeline) => void;
  toggleActionsBackward: GsapToggleActions;
  toggleActionsForward: GsapToggleActions;
};

export type MetaTimeline = {
  debug?: boolean | undefined;
  points: MetaTimelinePoint[];
  scope: ReactRef | Element | string;
  scrub?: number | boolean;
  setupGlobalTimeline: (tl: gsap.core.Timeline) => void;
  totalDuration: number;
  trigger: gsap.DOMTarget;
};

type UseMetaTimelineResult = {
  hiddenDivs: ReactElement;
};

// Note: I don't pass dependencies, so the animation NEVER gets re-created!
export function useMetaTimeline(cfg: MetaTimeline): UseMetaTimelineResult {
  // const [divsData, setDivsData] = useState<{height: number; id: string}[]>([]);

  const sortedPoints = useMemo(() => {
    const sortedPoints = [...cfg.points]
      .sort((a, b) => a.position - b.position)
      .map(p => ({...p, duration: 0}));

    const totalDuration = cfg.totalDuration;
    for (let i = 0; i < sortedPoints.length; ++i) {
      const p = sortedPoints[i];

      p.duration =
        (i + 1 < sortedPoints.length
          ? sortedPoints[i + 1].position
          : totalDuration) - p.position;
    }

    return sortedPoints;
  }, [cfg.points, cfg.totalDuration]);

  const divsData = useMemo(() => {
    const newDivsData: {height: number; id: string}[] = [];

    if (sortedPoints.length > 0 && sortedPoints[0].position > 0) {
      newDivsData.push({
        height: sortedPoints[0].position,
        id: `prologue`,
      });
    }

    for (let i = 0; i < sortedPoints.length; ++i) {
      const p = sortedPoints[i];

      // TODO: Use a helper func for class name
      newDivsData.push({
        height: p.duration,
        id: `${i}`,
      });
    }

    return newDivsData;
  }, [sortedPoints]);

  useGSAP(
    () => {
      const headerHeight =
        document.querySelector<HTMLElement>('header')?.offsetHeight ?? 0;

      for (let i = 0; i < sortedPoints.length; ++i) {
        const p = sortedPoints[i];

        const commonScrollTriggerVars: Partial<
          gsap.TimelineVars['scrollTrigger']
        > = {
          end: `top ${headerHeight}px`,
          markers: cfg.debug,
          start: `top ${headerHeight}px`,
          // TODO: Use a helper func for class name
          trigger: `.Impl-css-class-${i}`,
        };

        let forwardTl = gsap.timeline({
          scrollTrigger: {
            ...commonScrollTriggerVars,
            onEnter: () => {
              p.handler('forward');
            },
            toggleActions: p.toggleActionsForward,
          },
        });
        p.setupTimeline('forward', forwardTl);

        let backwardTl = gsap.timeline({
          scrollTrigger: {
            ...commonScrollTriggerVars,
            onLeaveBack: () => {
              p.handler('backward');
            },
            toggleActions: p.toggleActionsBackward,
          },
        });
        p.setupTimeline('backward', backwardTl);
      }

      const scrollTrigger: ScrollTrigger.Vars = {
        anticipatePin: 1,
        end: `${cfg.totalDuration} ${headerHeight}px`,
        pin: true,
        scrub: cfg.scrub ?? true,
        start: `+=0 +=${headerHeight}px`,
        trigger: cfg.trigger,
      };

      let tl = gsap.timeline({
        scrollTrigger,
      });
      cfg.setupGlobalTimeline && cfg.setupGlobalTimeline(tl);

      const handleResize = () => {
        // record state of animation
        const progress = tl.progress();
        const reversed = tl.reversed();

        // clear animation
        tl.progress(0).clear();
        cfg.setupGlobalTimeline && cfg.setupGlobalTimeline(tl);
        tl.progress(progress).reversed(reversed);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    },
    {
      // TODO figure out the dependencies so that it doesn't cause an infinite loop
      // dependencies: [sortedPoints],
      scope: cfg.scope,
    },
  );

  const debug = cfg.debug ?? false;

  const hiddenDivs = (
    <div style={{height: 0, marginBottom: 0, marginTop: 0}}>
      {divsData.map((divData, i) => (
        <div
          className={`Impl-css-class-${divData.id}`}
          key={`hidden-div-${divData.id}`}
          style={{
            backgroundColor: debug ? (i % 2 ? 'blue' : 'yellow') : undefined,
            height: divData.height,
            position: 'relative',
          }}>
          {cfg.debug ? (
            <div
              style={{
                color: debug
                  ? i % 2 && divData.height > 0
                    ? 'yellow'
                    : 'blue'
                  : undefined,
                fontSize: '2em',
                left: 0,
                position: 'absolute',
                top: 0,
              }}>
              {divData.id}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );

  return {hiddenDivs};
}

export function getReferenceDistance(): number {
  return 3 * Math.min(screen.width, screen.height);
}
