import React, {
  ReactElement,
  ReactNode,
  createContext,
  useMemo,
  useRef,
} from 'react';

import {SectionId} from '../components/ViewTracker';

export type CountryCode = string;

export type IntersectionContextType = {
  getLastVisitedSectionId(): SectionId;
  reportSectionVisible(sectionId: SectionId): void;
};

export const IntersectionContext = createContext<IntersectionContextType>({
  getLastVisitedSectionId() {
    console.error('No IntersectionContext.Provider above');
    throw new Error('No IntersectionContext.Provider above');
  },
  reportSectionVisible: () => {
    console.error('No IntersectionContext.Provider above');
    throw new Error('No IntersectionContext.Provider above');
  },
});

type Props = {
  children: ReactNode;
};

const SECTIONS_ORDER: SectionId[] = [
  'section_intro',
  'section_style',
  'section_data_fields',
  'section_long_press',
  'section_seconds',
  'section_languages',
  'section_mip_amoled',
  'section_install',
];

export function IntersectionContextProvider({children}: Props): ReactElement {
  const lastVisitedSectionIdRef = useRef<SectionId>('section_intro');

  const ctxValue = useMemo((): IntersectionContextType => {
    return {
      getLastVisitedSectionId() {
        return lastVisitedSectionIdRef.current;
      },
      reportSectionVisible(sectionId: SectionId) {
        const prevIdx = SECTIONS_ORDER.findIndex(
          s => s === lastVisitedSectionIdRef.current,
        );
        const newIdx = SECTIONS_ORDER.findIndex(s => s === sectionId);

        if (newIdx > prevIdx) {
          lastVisitedSectionIdRef.current = sectionId;
        }
      },
    };
  }, []);

  return (
    <IntersectionContext.Provider value={ctxValue}>
      {children}
    </IntersectionContext.Provider>
  );
}
