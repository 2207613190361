import {isAndroid, isIOS} from 'react-device-detect';

export function getConnectIQLink(appId: string): string {
  const encodedAppId = encodeURIComponent(appId);

  if (isAndroid) {
    const fallbackUrl = encodeURIComponent(
      `https://apps.garmin.com/apps/${encodedAppId}`,
    );
    return `intent://apps.garmin.com/apps/${encodedAppId}#Intent;scheme=https;S.browser_fallback_url=${fallbackUrl};end;`;
  } else if (isIOS) {
    return `https://apps.garmin.com/apps/${encodedAppId}`;
  } else {
    return `https://apps.garmin.com/apps/${encodedAppId}`;
  }
}
