import React, {ReactElement, useRef, useState} from 'react';

import CustomDetails from '../components/CustomDetails';
import {MetaTimelinePoint, useMetaTimeline} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import supportedLanguages from '../i18n/strings/supported_languages';
import NeuProWatchFace from '../watch_face/NeuPro';
import './css/SectionIntroduce.css';
import './css/SectionLanguages.css';

type Props = {};

export default function SectionLanguages({}: Props): ReactElement {
  // TODO: Use system language as the initial one
  const langs: (keyof typeof supportedLanguages)[] = ['en', 'kr', 'ar', 'th'];
  const N = langs.length - 0;

  // TODO: should depend on screen dimensions (width?)
  const DURATION_BETWEEN_LANGS = 700;
  const DURATION_OUTRO = 500;

  const [langOverride, setLangOverride] = useState(langs[0]);

  const containerRef = useRef<HTMLDivElement>(null);

  const DURATION_FADE_OUT = 0.2;
  const DURATION_FADE_IN = 0.2;
  const ROTATE_STEP = 60;

  const handlerTimeoutRef = useRef<NodeJS.Timeout>();

  const {hiddenDivs} = useMetaTimeline({
    // debug: true,
    points: langs.map(
      (lang, i): MetaTimelinePoint => ({
        handler(dir) {
          handlerTimeoutRef.current && clearTimeout(handlerTimeoutRef.current);

          handlerTimeoutRef.current = setTimeout(
            () =>
              setLangOverride(
                dir === 'forward' ? langs[i] : langs[Math.max(i - 1, 0)],
              ),
            DURATION_FADE_OUT * 1000,
          );
        },
        position: i * DURATION_BETWEEN_LANGS,
        setupTimeline(dir, tl) {
          if (i === 0) {
            return;
          }

          const targetStyle = '.Neu-Pro-data-field';
          tl.to(targetStyle, {
            duration: DURATION_FADE_OUT,
            ease: 'power2.inOut',
            opacity: 0,
            scale: 0.7,
          });
          tl.to(
            targetStyle,
            {
              duration: DURATION_FADE_IN,
              ease: 'power2.inOut',
              opacity: 1,
              scale: 1,
            },
            DURATION_FADE_OUT,
          );
        },
        toggleActionsBackward: 'reset none none play',
        toggleActionsForward: 'play none none reset',
      }),
    ),
    scope: containerRef,
    scrub: 1,
    setupGlobalTimeline(tl) {
      tl.fromTo(
        '.Flying-letters',
        {rotate: 0},
        {
          duration: DURATION_BETWEEN_LANGS * N + DURATION_OUTRO,
          ease: 'none',
          rotate: ROTATE_STEP * N,
        },
      );

      tl.fromTo(
        '.Flying-letters',
        {
          opacity: 0,
        },
        {
          duration: DURATION_BETWEEN_LANGS,
          opacity: 1,
        },
        0,
      );

      const R_OUTER = 1.2;
      const R_INNER = 0.5;

      for (let i = 0; i < N; ++i) {
        tl.fromTo(
          '.Flying-letters',
          {scale: R_INNER},
          {
            duration: DURATION_BETWEEN_LANGS / 2,
            ease: 'power1.inOut',
            scale: R_OUTER,
          },
          DURATION_BETWEEN_LANGS * i,
        );
        tl.to(
          '.Flying-letters',
          {
            duration: DURATION_BETWEEN_LANGS / 2,
            ease: 'power1.inOut',
            scale: R_INNER,
          },
          DURATION_BETWEEN_LANGS * (i + 0.5),
        );
      }

      tl.to(
        '.Flying-letters',
        {
          duration: DURATION_OUTRO,
          opacity: 0,
          scale: 0,
        },
        DURATION_BETWEEN_LANGS * N,
      );
    },
    totalDuration: N * DURATION_BETWEEN_LANGS + DURATION_OUTRO,
    trigger: '.Langs-section',
  });

  const letters = ['ظ', 'ญ', 'א', 'W', 'Ψ', 'Ж', '时'];

  return (
    <div ref={containerRef} style={{gap: 0}}>
      {hiddenDivs}
      <ViewTracker sectionId="section_languages">
        <section
          className="Langs-section section-with-image"
          style={{marginTop: 0}}>
          <div className="section-title-cell">
            <h2>Speaks your language</h2>
          </div>
          <div className="section-image-cell">
            <div
              className="Watch-container section-image-clip"
              style={{position: 'relative'}}>
              <div className="Watch-image Abs-fill-parent" />
              <div className="Abs-fill-parent Introduce-img-wf-placeholder">
                <NeuProWatchFace
                  dataFieldBottom="min_max_temperature"
                  dataFieldLeft="battery_days"
                  dataFieldRight="recovery_time"
                  dataFieldTop="month_monthday"
                  langOverride={langOverride}
                />
                <div className="Abs-fill-parent Inline-size-container">
                  <div className="Abs-fill-parent Flying-letters">
                    <div style={{position: 'relative'}}>
                      {[...Array(7)].map((_x, i) => (
                        <div
                          className="Single-flying-letter"
                          key={`letter-${i}`}>
                          <div className="Abs-fill-parent inner">
                            {letters[i]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-text-cell">
            <p className="common-paragraph">
              With support for <b>36 languages</b>, it’s ready to connect with
              you — personalizing your experience no matter your region or
              language.
            </p>
            <div>
              <CustomDetails title={'See all languages'}>
                <ul className="expandable-list">
                  <li>العربية</li>
                  <li>Български</li>
                  <li>Čeština</li>
                  <li>Dansk</li>
                  <li>Deutsch</li>
                  <li>Eesti</li>
                  <li>Ελληνικά</li>
                  <li>English</li>
                  <li>Español</li>
                  <li>Français</li>
                  <li>עברית</li>
                  <li>Hrvatski</li>
                  <li>Italiano</li>
                  <li>Latviešu</li>
                  <li>Lietuvių</li>
                  <li>Magyar</li>
                  <li>Nederlands</li>
                  <li>Norsk</li>
                  <li>Polski</li>
                  <li>Português</li>
                  <li>Română</li>
                  <li>Русский</li>
                  <li>Slovenčina</li>
                  <li>Slovenščina</li>
                  <li>Suomi</li>
                  <li>Svenska</li>
                  <li>Türkçe</li>
                  <li>Українська</li>
                  <li>简体中文</li>
                  <li>繁體中文</li>
                  <li>한국어</li>
                  <li>ไทย</li>
                  <li>Bahasa Indonesia</li>
                  <li>Bahasa Melayu</li>
                  <li>Tiếng Việt</li>
                  <li>日本語</li>
                </ul>
              </CustomDetails>
            </div>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}
