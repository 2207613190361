import React, {ReactElement} from 'react';

import './css/CookieDialog.css';

export function CookieDialog(): ReactElement {
  return (
    <div className="cookie-dialog-positioning">
      <div className="cookie-dialog">
        <div className="cookie-dialog-internal">
          <h2>We use cookies</h2>
          <p>
            By clicking &ldquo;Accept All,&ldquo; you consent to the use of
            cookies and similar technologies by us and our partners to enhance
            your experience, analyze site performance, and deliver personalized
            ads and content.
            <br />
            <br /> Essential cookies, required for navigating our site, will
            always be active. For all other cookies, we&rsquo;ll only use and
            share your data with your consent.
          </p>
          <div className="cookie-buttons">
            <button onClick={closeCookieDialog}>Allow all</button>
            <button className="button-secondary">
              Only use essential cookies
            </button>
          </div>
        </div>
        {/* <div className="reject-all-container">
          <p className="Scroll-to-learn-more">Reject all</p>
          <span className="expandable-icon"></span>
        </div> */}
      </div>
    </div>
  );
}

export function openCookieDialog(): void {
  const el = document.getElementsByClassName('cookie-dialog-positioning')[0];

  if (!el) {
    console.error('Cookie Dialog element not found');
  }

  if (!el.classList.contains(OPEN_CLASSNAME)) {
    el.classList.add(OPEN_CLASSNAME);
  }
}

function closeCookieDialog(): void {
  const el = document.getElementsByClassName('cookie-dialog-positioning')[0];

  if (!el) {
    console.error('Cookie Dialog element not found');
  }

  if (el.classList.contains(OPEN_CLASSNAME)) {
    el.classList.remove(OPEN_CLASSNAME);
  }
}

const OPEN_CLASSNAME = 'open';
