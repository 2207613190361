import React, {ReactElement} from 'react';

import {NEU_PRO_APP_ID} from '../neu_pro/NeuProInfo';
import OpenConnectIqButton from './OpenConnectIqButton';
import {CountrySelector, usePrice} from './PriceUtils';

type Props = {
  buttonElementName: string;
};

export default function DownloadParagraph({
  buttonElementName,
}: Props): ReactElement {
  const price = usePrice();

  return (
    <>
      <p className="download-paragraph">
        <OpenConnectIqButton
          appId={NEU_PRO_APP_ID}
          elementName={buttonElementName}
          title={`Download for ${price}`}
        />
        <br />
        Not for you? <br />
        Claim a full refund within 2 days.
      </p>
      <CountrySelector />
    </>
  );
}
