import React, {useEffect, useRef} from 'react';

import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
  setConsent,
} from '@firebase/analytics';
import {initializeApp} from '@firebase/app';
import {getPerformance} from '@firebase/performance';
import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import AppHelmet from './AppHelmet';
import Page from './components/Page';
import {useErrorHandlers} from './utils/ErrorHandlers';
import {isDev} from './utils/Utils';
import useCloudFunctionsWarmUp from './utils/useCloudFunctionsWarmUp';
import useConsentHandler from './utils/useConsentHandler';
import useIubenda from './utils/useIubenda';

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin);

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBQPTitXLbG2MHrabmZp9xwt_lPrLz3SB0',
  appId: '1:1093784911993:web:5aa3f9ea06a9a1c28814a8',
  authDomain: 'connectiq-441119.firebaseapp.com',
  measurementId: 'G-BHMHF3ZY59',
  messagingSenderId: '1093784911993',
  projectId: 'connectiq-441119',
  storageBucket: 'connectiq-441119.firebasestorage.app',
};

// @ts-ignore
window['gtag_enable_tcf_support'] = true;

setConsent({
  ad_personalization: 'denied',
  ad_storage: 'denied',
  ad_user_data: 'denied',
  analytics_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'denied',
});

const app = initializeApp(firebaseConfig);
setAnalyticsCollectionEnabled(getAnalytics(), false);
setAnalyticsCollectionEnabled(getAnalytics(app), false);

if (!isDev()) {
  const perf = getPerformance(app);
}

function App() {
  useErrorHandlers();

  useCloudFunctionsWarmUp();

  useIubenda();

  useConsentHandler();

  const prevWRef = useRef(0);

  useEffect(() => {
    // We need to call ScrollTrigger.refresh() in a bunch of situations,
    // e.g. when mobile phone changes orientation.
    // However, on iPhone 8 this leads to very bad stuttering as brower
    // panel at the top and at the bottom hide/show up when scrolling.
    // Due to this issue, I'm only doing this when viewport width changes.
    // This is pretty hacky, but seems like a reasonable compromise.
    const handleResize = () => {
      if (prevWRef.current !== window.outerWidth) {
        ScrollTrigger.refresh();
        prevWRef.current = window.outerWidth;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <AppHelmet />
      <Page />
    </>
  );
}

export default App;
