import React, {ReactElement} from 'react';

export default function ScrollToLearnMore(): ReactElement {
  // useGSAP(() => {
  //   const scrollTrigger: ScrollTrigger.Vars = {
  //     end: `top top`,
  //     markers: true,
  //     scrub: true,
  //     start: 'top 70%',
  //     // trigger: 'section:nth-child(1)',
  //     trigger: '.Section-install',
  //     // trigger: 'section:last-of-type',
  //   };

  //   let tl = gsap.timeline({scrollTrigger});

  //   tl.fromTo('.Scroll-to-learn-more-container', {opacity: 1}, {opacity: 0}, 0);
  // });

  return (
    <div className="Scroll-to-learn-more-container">
      <div className="Scroll-to-learn-more">
        <p>Scroll to learn more</p>
        <span className="Scroll-to-learn-more-caret"></span>
      </div>
    </div>
  );
}
