import React, {ReactElement, useRef, useState} from 'react';

import {MetaTimelinePoint, useMetaTimeline} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import NeuProWatchFace, {SecondsIndicatorType} from '../watch_face/NeuPro';
import './css/SectionIntroduce.css';
import './css/SectionSeconds.css';

type Props = {};

export default function SectionSeconds({}: Props): ReactElement {
  const options: SecondsIndicatorType[] = ['analog', 'right_field', 'off'];

  const [indicatorType, setIndicatorType] =
    useState<SecondsIndicatorType>('analog');

  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: should depend on screen dimensions (width?)
  const DURATION_BETWEEN = 1000;
  const PAUSE_BEFORE_MOVE = 100;
  const PAUSE_AFTER_MOVE = 100;

  const {hiddenDivs} = useMetaTimeline({
    // debug: true,
    points: options.map(
      (option, i): MetaTimelinePoint => ({
        handler(dir) {
          setIndicatorType(
            options[
              dir === 'forward' ? Math.min(i + 1, options.length - 1) : i
            ],
          );
        },
        position: (i + 1) * DURATION_BETWEEN,
        setupTimeline(dir, tl) {
          const target =
            i === (dir === 'forward' ? 0 : 1)
              ? '.Neu-pro-digital-seconds'
              : '.Neu-pro-analog-seconds';

          const DURATION = 0.3;
          const MIN_OPACITY = 0.4;

          tl.to(target, {duration: DURATION, opacity: MIN_OPACITY});
          tl.to(target, {duration: DURATION, opacity: 1});
          tl.to(target, {duration: DURATION, opacity: MIN_OPACITY});
          tl.to(target, {duration: DURATION, opacity: 1});
          tl.to(target, {duration: DURATION, opacity: MIN_OPACITY});
          tl.to(target, {duration: DURATION, opacity: 1});
        },
        toggleActionsBackward: 'reset none none play',
        toggleActionsForward: 'play none none reset',
      }),
    ),
    scope: containerRef,
    setupGlobalTimeline(tl) {
      // Only two "next" progress bars
      const N = 2;
      for (let i = 1; i <= N; ++i) {
        const target = `.label-${i + 1} .label-progress`;

        const moveStart = DURATION_BETWEEN * (i - 1) + PAUSE_BEFORE_MOVE;
        const moveDuration =
          DURATION_BETWEEN - PAUSE_BEFORE_MOVE - PAUSE_AFTER_MOVE;

        tl.fromTo(
          target,
          {
            opacity: 1,
            width: '0%',
          },
          {
            duration: moveDuration * (1 - 2 * 0.1),
            ease: 'power2.in',
            opacity: 1,
            width: '100%',
          },
          moveStart,
        );

        tl.fromTo(
          target,
          {
            borderBottomRightRadius: '0px 50px',
            borderTopRightRadius: '0px 50px',
          },
          {
            borderBottomRightRadius: '25px 50px',
            borderTopRightRadius: '25px 50px',
            duration: DURATION_BETWEEN / 9,
          },
          moveStart,
        );

        tl.to(
          target,
          {
            borderBottomRightRadius: '60px 50px',
            borderTopRightRadius: '60px 50px',
            duration: DURATION_BETWEEN / 3 + DURATION_BETWEEN / 9,
          },
          moveStart + moveDuration / 9,
        );

        tl.to(
          target,
          {
            borderBottomRightRadius: '0px 50px',
            borderTopRightRadius: '0px 50px',
            duration: DURATION_BETWEEN / 3,
          },
          moveStart + moveDuration * (2 / 3),
        );

        tl.to(
          target,
          {opacity: 0},
          // TODO: reimplement
          DURATION_BETWEEN * (i - 1) + DURATION_BETWEEN * 1.2,
        );
      }

      // TODO
      tl.to(
        `.label-2 .label-progress`,
        {duration: DURATION_BETWEEN},
        DURATION_BETWEEN * N,
      );
    },
    totalDuration: options.length * DURATION_BETWEEN,
    trigger: '.Seconds-section',
  });

  return (
    <div ref={containerRef} style={{gap: 0}}>
      {hiddenDivs}
      <ViewTracker sectionId="section_seconds">
        <section className="Seconds-section section-with-image">
          <div className="section-title-cell">
            <h2>Every second counts</h2>
          </div>
          <div className="section-image-cell">
            <div
              className="Watch-container section-image-clip"
              style={{position: 'relative'}}>
              <div className="Watch-image Abs-fill-parent" />
              <div className="Abs-fill-parent Introduce-img-wf-placeholder">
                <NeuProWatchFace
                  secondsIndicator={indicatorType}
                  addAllSecondIndicatorsToDom
                />
              </div>
            </div>
          </div>
          <div className="section-text-cell">
            <div>
              <p className="toggle">
                <input
                  checked={indicatorType === 'analog'}
                  className="checkbox"
                  id="cb_analog"
                  type="radio"
                  readOnly
                />
                <label className="label-1" htmlFor="cb_analog">
                  Analog
                </label>
                <input
                  checked={indicatorType === 'right_field'}
                  className="checkbox"
                  id="cb_right_field"
                  type="radio"
                  readOnly
                />
                <label
                  className="label-2"
                  htmlFor="cb_right_field"
                  style={{overflow: 'clip', position: 'relative', zIndex: 0}}>
                  <span
                    className="label-progress Abs-fill-parent"
                    style={{
                      backgroundColor: '#0fa',
                      position: 'absolute',
                      width: '0%',
                      zIndex: -1,
                    }}
                  />
                  Digital
                </label>
                <input
                  checked={indicatorType === 'off'}
                  className="checkbox"
                  id="cb_off"
                  type="radio"
                  readOnly
                />
                <label
                  className="label-3"
                  htmlFor="cb_off"
                  style={{overflow: 'clip', position: 'relative', zIndex: 0}}>
                  <span
                    className="label-progress Abs-fill-parent"
                    style={{
                      backgroundColor: '#0fa',
                      position: 'absolute',
                      width: '0%',
                      zIndex: -1,
                    }}
                  />
                  No seconds
                </label>
              </p>
            </div>
            <p>
              Display seconds your way: choose digital, analog, or go without.
            </p>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}
