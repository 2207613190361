import * as ar from './ar.json';
import * as bg from './bg.json';
import * as cz from './cz.json';
import * as en from './en.json';
import * as es from './es.json';
import * as fr from './fr.json';
import * as gr from './gr.json';
import * as he from './he.json';
import * as kr from './kr.json';
import * as th from './th.json';

export default {
  l18nResources: {ar, bg, cz, en, es, fr, gr, he, kr, th},
};
