import React, {ReactElement} from 'react';

import {Helmet} from 'react-helmet-async';

export default function AppHelmet(): ReactElement {
  return (
    <Helmet>
      <meta content="Neu Pro watch face promo page" name="description" />
      <link
        as="font"
        crossOrigin="anonymous"
        href="./assets/fonts/SairaCondensed-SemiBold.ttf"
        rel="preload"
      />
      {/* Meta Pixel */}
      <script>
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('consent', 'revoke');
        fbq('init', '427974807051131');
        fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        {`<img
          height="1"
          src="https://www.facebook.com/tr?id=427974807051131&ev=PageView&noscript=1"
          style="display:none"
          width="1"
        />`}
      </noscript>
      {/* Reddit Pixel */}
      <script>
        {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
          // We do these once the consent is given
          // rdt('init','a2_fkxof39zpg71');
          // rdt('track', 'PageVisit');
        `}
      </script>
    </Helmet>
  );
}

// Reddit Pixel ID: a2_fkxof39zpg71
