import React, {ReactElement, ReactNode, useRef} from 'react';

import {ScrollTrigger} from 'gsap/ScrollTrigger';

type Props = {
  children: ReactNode;
  title: string;
};

const OPEN_CLASSNAME = 'open';

export default function CustomDetails({children, title}: Props): ReactElement {
  const detailsRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLElement>(null);

  const toggleOpen = () => {
    const div = detailsRef.current;
    if (!div) {
      return;
    }

    if (div.classList.contains(OPEN_CLASSNAME)) {
      iconRef.current?.classList.remove(OPEN_CLASSNAME);
      div.classList.remove(OPEN_CLASSNAME);
    } else {
      iconRef.current?.classList.add(OPEN_CLASSNAME);
      div.classList.add(OPEN_CLASSNAME);
    }

    ScrollTrigger.refresh();
  };

  return (
    <div className="custom-details">
      <div className="custom-details-button-div">
        <button className="custom-details-toggle-button" onClick={toggleOpen}>
          {title}
          <span className="expandable-icon" ref={iconRef}></span>
        </button>
      </div>
      <div className="custom-details-fold" ref={detailsRef}>
        {children}
      </div>
    </div>
  );
}
