import {useEffect} from 'react';

import {getAnalytics, logEvent} from '@firebase/analytics';

export function useErrorHandlers() {
  useEffect(() => {
    // TODO: use addEventListener?
    window.onerror = function (message, source, lineno, colno, error) {
      try {
        const normalizedMsg =
          typeof message === 'string' ? message : message.type;
        const analyticsPayload = {
          columnNumber: colno,
          errorMessage: normalizedMsg.slice(0, 100),
          lineNumber: lineno,
          source: source?.slice(0, 100),
          stackTrace: error
            ? error.stack?.slice(0, 100)
            : 'No stack trace available',
        };

        logEvent(getAnalytics(), 'exception', analyticsPayload);
      } catch (e) {
        console.log('Error while logging another error: ', e);
      }
    };
  }, []);

  useEffect(() => {
    const handler = function (event: WindowEventMap['unhandledrejection']) {
      try {
        const analyticsPayload = {
          reason: event.reason
            ? event.reason.message.slice(0, 100)
            : 'No reason available',
          stackTrace: event.reason
            ? event.reason.stack.slice(0, 100)
            : 'No stack trace available',
        };

        logEvent(
          getAnalytics(),
          'unhandled_promise_rejection',
          analyticsPayload,
        );
      } catch (e) {
        console.log('Error while logging unhandled promise rejection: ', e);
      }
    };

    window.addEventListener('unhandledrejection', handler);
    return () => window.removeEventListener('unhandledrejection', handler);
  }, []);
}
