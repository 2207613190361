import {useContext, useEffect} from 'react';

import {ConsentSetterContext} from './ConsentContext';
import {nullthrows} from './Utils';

export enum PreferencePurpose {
  Marketing = '5',
  Measurement = '4',
  Necessary = '1',
}

export enum UsPr {
  SharingOfInfo = 'sh',
  TargetedAdvertising = 'adv',
}

type PreferenceObj = {
  ccpa: {
    // TODO: test ccpa as well
    firstAcknowledgeDate: string;
    optOutDate: string;
    uspString: string;
  };
  purposes: {
    [k in PreferencePurpose]: boolean;
  };
  timestamp: string;
  uspr: {
    [k in UsPr]: boolean;
  };
};

type Iub = {
  csConfiguration: {
    callback: {
      // See https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide#:~:text=collection%20of%20consent.-,Callbacks,-callback%20%7B%7D%20(object
      onConsentGiven?: () => void;
      onConsentRejected?: () => void;
      onPreferenceExpressed?: (preferences: PreferenceObj) => void;
      onReady?: () => void;
    };
    cookiePolicyId: number;
    lang: 'en';
    siteId: number;
    storage: {useSiteId: boolean};
  };
};

export default function useIubenda() {
  const ctx = useContext(ConsentSetterContext);

  useEffect(() => {
    // @ts-ignore
    const _iub: Iub = window._iub || [];
    // @ts-ignore
    window._iub = _iub;

    _iub.csConfiguration = {
      callback: {
        onReady() {
          try {
            patchIubendaCss();
          } catch (e) {
            console.warn("Couldn't update styles");
          }
        },
      },
      cookiePolicyId: Number.parseInt(
        nullthrows(
          process.env.REACT_APP_IUBENDA_COOKIE_POLICY_ID,
          'REACT_APP_IUBENDA_COOKIE_POLICY_ID',
        ),
      ),
      lang: 'en',
      siteId: Number.parseInt(
        nullthrows(
          process.env.REACT_APP_IUBENDA_SITE_ID,
          'REACT_APP_IUBENDA_SITE_ID',
        ),
      ),
      storage: {useSiteId: true},
    };

    const script1 = document.createElement('script');
    script1.src = 'https://cs.iubenda.com/autoblocking/3857931.js';
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = 'https://cdn.iubenda.com/cs/gpp/stub.js';
    document.body.appendChild(script2);

    const iubendaScript = document.createElement('script');
    iubendaScript.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js';
    iubendaScript.async = true;
    document.body.appendChild(iubendaScript);

    return () => {
      // Clean up the script when component unmounts
      document.body.removeChild(iubendaScript);
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    const iubObj: Iub = window._iub;
    iubObj.csConfiguration.callback.onPreferenceExpressed = p => {
      // TODO: These should actually be based on user's region
      ctx.setConsentSettings({
        adTrackingAllowed:
          p.purposes[PreferencePurpose.Marketing] &&
          p.uspr[UsPr.TargetedAdvertising] &&
          p.uspr[UsPr.SharingOfInfo],
        analyticsAllowed:
          p.purposes[PreferencePurpose.Measurement] &&
          p.uspr[UsPr.SharingOfInfo],
      });
    };
  }, [ctx]);
}

function patchIubendaCss() {
  const banner = window.document.getElementById('iubenda-cs-banner');
  // console.log('banner', banner);
  const bannerContents = banner?.getElementsByClassName(
    'iubenda-banner-content',
  );
  if (bannerContents) {
    for (let i = 0; i < bannerContents.length; ++i) {
      const bannerContent = bannerContents[i] as HTMLElement;
      bannerContent?.style.setProperty('padding-top', '0.3em', 'important');
      bannerContent?.style.setProperty('padding-bottom', '0.3em', 'important');

      const paragraph = document.getElementById('iubenda-cs-paragraph');
      if (paragraph) {
        paragraph?.style.setProperty('margin-top', '0.3em', 'important');
        paragraph?.style.setProperty('margin-bottom', '0.3em', 'important');
      }
    }
  }

  const optGroups = banner?.getElementsByClassName('iubenda-cs-opt-group');
  if (optGroups) {
    for (let i = 0; i < optGroups.length; ++i) {
      const optGroup = optGroups[i] as HTMLElement;
      optGroup?.style.setProperty('margin-top', '-0.4em', 'important');
      optGroup?.style.setProperty('margin-bottom', '0em', 'important');

      const buttons = optGroup.getElementsByTagName('button');
      if (buttons) {
        for (let j = 0; j < buttons.length; ++j) {
          const button = buttons[j] as HTMLElement;
          button?.style.setProperty('margin-top', '0.3em', 'important');
          button?.style.setProperty('margin-bottom', '0.3em', 'important');
        }
      }
    }
  }
}
