import React, {useRef} from 'react';
import {ReactElement} from 'react';

import '../App.css';
import '../css/Section.css';
import SectionDataFields from '../neu_pro/SectionDataFields';
import SectionInstall from '../neu_pro/SectionInstall';
import SectionIntroduce from '../neu_pro/SectionIntroduce';
import SectionLanguages from '../neu_pro/SectionLanguages';
import SectionLongPress from '../neu_pro/SectionLongPress';
import SectionMipAmoled from '../neu_pro/SectionMipAmoled';
import SectionSeconds from '../neu_pro/SectionSeconds';
import SectionStyle from '../neu_pro/SectionStyle';
import {CookieDialog} from './CookieDialog';
import ScreenHeader from './ScreenHeader';
import ScrollToLearnMore from './ScrollToLearnMore';

type Props = {};

export default function Page({}: Props): ReactElement {
  const container = useRef(null);

  return (
    <div className="App" ref={container}>
      {/* TODO: clean up the top-level divs */}
      <ScreenHeader />
      <ScrollToLearnMore />
      <div className="Main-div">
        <SectionIntroduce />
        <SectionStyle />
        <SectionDataFields />
        <SectionLongPress />
        <SectionSeconds />
        <SectionLanguages />
        <SectionMipAmoled />
        <SectionInstall />
      </div>
      <CookieDialog />
      <footer />
    </div>
  );
}
