import React, {
  ReactElement,
  ReactNode,
  createContext,
  useMemo,
  useState,
} from 'react';

export type CountryCode = string;

export type CountryContextType = {
  countryCode: CountryCode | undefined;
  hasManuallyChangedCountry: boolean;
};

export const CountryContext = createContext<CountryContextType>({
  countryCode: undefined,
  hasManuallyChangedCountry: false,
});

export type CountrySetterContextType = {
  setCountryCode(countryCode: CountryCode): void;
};

export const CountrySetterContext = createContext<CountrySetterContextType>({
  setCountryCode(countryCode: CountryCode) {
    console.error('No CountrySetterContext.Provider above');
    throw new Error('No CountrySetterContext.Provider above');
  },
});

type Props = {
  children: ReactNode;
};

export function CountryContextProvider({children}: Props): ReactElement {
  const [countryCodeOverride, setCountryCodeOverride] = useState<
    CountryCode | undefined
  >(undefined);

  const ctxValue = useMemo((): CountryContextType => {
    return {
      countryCode: countryCodeOverride ?? getCountryFromLocale(),
      hasManuallyChangedCountry: countryCodeOverride != null,
    };
  }, [countryCodeOverride]);

  const setterCtxValue = useMemo(
    (): CountrySetterContextType => ({
      setCountryCode(countryCode) {
        setCountryCodeOverride(countryCode);
      },
    }),
    [],
  );

  return (
    <CountrySetterContext.Provider value={setterCtxValue}>
      <CountryContext.Provider value={ctxValue}>
        {children}
      </CountryContext.Provider>
    </CountrySetterContext.Provider>
  );
}

// TODO: write tests
function getCountryFromLocale(): CountryCode | undefined {
  try {
    const lang = navigator.language;
    try {
      const locale = new Intl.Locale(lang);
      return locale.region;
    } catch (e) {
      console.warn('Intl.Locale', e);
    }

    const langArray = lang.split('-');
    if (langArray.length < 2) {
      return undefined;
    }

    const candidate = langArray[langArray.length - 1];
    return /^[A-Z]{2}$/.test(candidate) ? candidate : undefined;
  } catch (e) {
    console.warn('getCountryFromLocale', e);
    return undefined;
  }
}
