import React, {ReactElement, useRef} from 'react';

import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';

import DownloadParagraph from '../components/DownloadParagraph';
import UserReview from '../components/UserReview';
import ViewTracker from '../components/ViewTracker';
import './css/SectionInstall.css';
import './css/SectionIntroduce.css';

type Props = {};

export default function SectionInstall({}: Props): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const scrollTrigger: ScrollTrigger.Vars = {
      end: `top top`,
      // markers: true,
      scrub: true,
      start: 'top 70%',
      trigger: '.Section-install',
    };

    let tl = gsap.timeline({scope: containerRef, scrollTrigger});

    tl.fromTo('.Scroll-to-learn-more-container', {opacity: 1}, {opacity: 0}, 0);
  });

  return (
    <div ref={containerRef} style={{gap: 0}}>
      <ViewTracker sectionId="section_install">
        <section className="section-with-image Section-install">
          <div className="section-title-cell">
            <h2>And also</h2>
          </div>
          <div className="section-image-cell">
            <div className="section-image-noclip">
              <UserReview
                author="William"
                source="connect_iq"
                text="This is the first watch face I've ever paid for. Elegant and simple, just how I like it. "
              />
            </div>
          </div>
          <div className="section-text-cell">
            <ul className="icon-list">
              <li>
                <span>
                  The <b>always-on display</b> ensures the time is visible at a
                  glance.
                </span>
              </li>
              <li>
                <span>
                  Designed for <b>energy efficiency</b>, it helps your watch use
                  less power, extending battery life.
                </span>
              </li>
            </ul>
            <DownloadParagraph buttonElementName="install_section_button" />
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}
