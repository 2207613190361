import React, {ReactElement} from 'react';

import './css/UserReview.css';

type UserReviewProps = {
  author: string;
  source: 'connect_iq';
  text: string;
};

export default function UserReview({
  author,
  source,
  text,
}: UserReviewProps): ReactElement {
  const sourceStr = 'Connect IQ user';

  return (
    <div className="User-review-outer-box">
      <div className="User-review-box">
        <div className="User-review-quote-icon" />
        <p className="User-review-text">{text}</p>
        <Rating />
        <p className="User-review-author">
          {author}, {sourceStr}
        </p>
      </div>
    </div>
  );
}

type RatingProps = {};

function Rating({}: RatingProps): ReactElement {
  return (
    <div className="review-stars-container">
      <div className="review-star" />
      <div className="review-star" />
      <div className="review-star" />
      <div className="review-star" />
      <div className="review-star" />
    </div>
  );
}
