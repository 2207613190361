import React, {ReactElement, useContext} from 'react';

import {CountryContext, CountrySetterContext} from '../utils/CountryContext';

const DEFAULT_PRICE = '$4.99';

type PriceInCountry = {
  countryName: string;
  price: string;
};

const REGIONAL_PRICES: Record<string, PriceInCountry> = {
  AR: {countryName: 'Argentina', price: 'AR$5099'},
  AT: {countryName: 'Austria', price: '€5.99'},
  AU: {countryName: 'Australia', price: 'A$7.99'},
  BE: {countryName: 'Belgium', price: '€5.99'},
  CA: {countryName: 'Canada', price: 'CA$8.49'},
  CH: {countryName: 'Switzerland', price: 'CHF 5.20'},
  CL: {countryName: 'Chile', price: '€5.99'},
  CY: {countryName: 'Cyprus', price: '€5.99'},
  CZ: {countryName: 'Czech Republic', price: 'Kč 159'},
  DE: {countryName: 'Germany', price: '€5.99'},
  DK: {countryName: 'Denmark', price: 'kr. 45.50'},
  EE: {countryName: 'Estonia', price: '€5.99'},
  ES: {countryName: 'Spain', price: '€5.99'},
  FI: {countryName: 'Finland', price: '€5.99'},
  FR: {countryName: 'France', price: '€5.99'},
  GB: {countryName: 'United Kingdom', price: '£4.99'},
  GR: {countryName: 'Greece', price: '€5.99'},
  IE: {countryName: 'Ireland', price: '€5.99'},
  IT: {countryName: 'Italy', price: '€5.99'},
  LT: {countryName: 'Lithuania', price: '€5.99'},
  LU: {countryName: 'Luxembourg', price: '€5.99'},
  LV: {countryName: 'Latvia', price: '€5.99'},
  MT: {countryName: 'Malta', price: '€5.99'},
  MX: {countryName: 'Mexico', price: 'MX$97.99'},
  NL: {countryName: 'Netherlands', price: '€5.99'},
  NO: {countryName: 'Norway', price: 'kr 70'},
  NZ: {countryName: 'New Zealand', price: 'NZ$8.75'},
  PT: {countryName: 'Portugal', price: '€5.99'},
  RO: {countryName: 'Romania', price: 'lei 39'},
  SE: {countryName: 'Sweden', price: 'kr 70'},
  SI: {countryName: 'Slovenia', price: '€5.99'},
  SK: {countryName: 'Slovakia', price: '€5.99'},
  TH: {countryName: 'Thailand', price: '฿199'},
  US: {countryName: 'United States', price: '$4.99'},
  VN: {countryName: 'Vietnam', price: '₫139,099'},
};

export function usePrice(): string {
  const countryCtx = useContext(CountryContext);
  return REGIONAL_PRICES[countryCtx.countryCode ?? '']?.price ?? DEFAULT_PRICE;
}

type CountrySelectorProps = {};

export function CountrySelector({}: CountrySelectorProps): ReactElement {
  const countryCtx = useContext(CountryContext);
  const countrySetterCtx = useContext(CountrySetterContext);

  const selectedValue =
    countryCtx.countryCode && countryCtx.countryCode in REGIONAL_PRICES
      ? countryCtx.countryCode
      : 'none';

  return (
    <p className="country-selector-paragraph">
      <small>
        Price in{' '}
        <select
          value={selectedValue}
          onChange={e => countrySetterCtx.setCountryCode(e.target.value)}>
          <option value="none" disabled>
            select country
          </option>
          {Object.entries(REGIONAL_PRICES).map(([id, e]) => (
            <option key={`country-${id}`} value={id}>
              {e.countryName}
            </option>
          ))}
        </select>
        {countryCtx.hasManuallyChangedCountry && (
          <span className="price-disclaimer">
            <br />
            Regional pricing is determined by Garmin and is outside of our
            control.
          </span>
        )}
      </small>
    </p>
  );
}
