const supportedLanguages = {
  ar: '',
  bg: '',
  cz: '',
  en: '',
  es: '',
  fr: '',
  gr: '',
  he: '',
  kr: '',
  th: '',
};
export default supportedLanguages;
