import React, {ReactElement, useRef} from 'react';

import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';

import {getReferenceDistance} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import NeuProWatchFace from '../watch_face/NeuPro';
import './css/SectionIntroduce.css';
import './css/SectionMipAmoled.css';

type Props = {};

export default function SectionMipAmoled({}: Props): ReactElement {
  const containerRef = useRef(null);

  const N = 5;
  const zigzagPoints = [
    {xs: [0, 25 + 1, 50 + 0, 75 - 1, 100], y: 0},
    {xs: [0, 25 + 3, 50 + 2, 75 + 3, 100], y: 20},
    {xs: [0, 25 - 1, 50 - 2, 75 - 3, 100], y: 45},
    {xs: [0, 25 + 2, 50 + 5, 75 + 2, 100], y: 65},
    {xs: [0, 25 - 1, 50 - 3, 75 - 2, 100], y: 80},
    {xs: [0, 25 + 3, 50 + 0, 75 + 1, 100], y: 100},
  ];

  const getPoints = (i: number, suffix: string) =>
    zigzagPoints.map(p => `${p.xs[i]}${suffix} ${p.y}${suffix}`).join(', ');

  const clipPathZigZag = `
    50% 0%,
    52% 20%,
    48% 45%,
    55% 65%,
    47% 80%,
    50% 100%
  `;

  useGSAP(
    () => {
      const scrollDistance = getReferenceDistance();

      const headerHeight =
        document.querySelector<HTMLElement>('header')?.offsetHeight ?? 0;

      const scrollTrigger: ScrollTrigger.Vars = {
        anticipatePin: 1,
        end: `${scrollDistance} ${headerHeight}px`,
        // markers: true,
        pin: true,
        scrub: 0.5,
        start: `+=0 +=${headerHeight}px`,
        trigger: '.Mip-amoled-section',
      };

      let tl = gsap.timeline({scrollTrigger});

      const DURATION = 50;

      const animCommon: Pick<gsap.TweenVars, 'duration' | 'ease'> = {
        duration: DURATION,
        ease: 'none',
      };

      tl.fromTo(
        '.Mip-amoled-delimiter',
        {opacity: 0},
        {opacity: 1, ...animCommon},
        0,
      );

      for (let i = 0; i < N - 1; ++i) {
        const position = DURATION * (i + 1);
        tl.fromTo(
          '.Mip-amoled-delimiter',
          {attr: {points: getPoints(i, '')}},
          {attr: {points: getPoints(i + 1, '')}, ...animCommon},
          position,
        );
        tl.fromTo(
          '.Mip-amoled-clipPathL',
          {
            clipPath: `polygon(0% 0%, ${getPoints(i, '%')}, 0% 100%)`,
          },
          {
            clipPath: `polygon(0% 0%, ${getPoints(i + 1, '%')}, 0% 100%)`,
            ...animCommon,
          },
          position,
        );
        tl.fromTo(
          '.Mip-amoled-clipPathR',
          {
            clipPath: `polygon(100% 0%, ${getPoints(i, '%')}, 100% 100%)`,
          },
          {
            clipPath: `polygon(100% 0%, ${getPoints(i + 1, '%')}, 100% 100%)`,
            ...animCommon,
          },
          position,
        );
      }

      tl.fromTo(
        '.Mip-amoled-delimiter',
        {opacity: 1},
        {opacity: 0, ...animCommon, duration: DURATION * 3},
        DURATION * (N - 1),
      );

      tl.fromTo(
        '.Mip-amoled-delimiter',
        {},
        {...animCommon, duration: DURATION * 1.5},
        DURATION * (N - 1 + 3),
      );
    },
    {scope: containerRef},
  );

  return (
    <div ref={containerRef}>
      <ViewTracker sectionId="section_mip_amoled">
        <section className="Mip-amoled-section section-with-image">
          <div className="section-title-cell">
            <h2>Highly readable</h2>
            <h3>on both MIP and AMOLED displays</h3>
          </div>
          <div className="section-image-cell">
            <div
              className="Watch-container section-image-clip"
              style={{position: 'relative'}}>
              <div
                className="Abs-fill-parent Mip-amoled-clipPathL"
                style={{
                  clipPath: `polygon(0% 0%, ${clipPathZigZag}, 0% 100%)`,
                }}>
                <div className="Watch-image2 Abs-fill-parent" />
                <div className="Abs-fill-parent Introduce-img-wf-placeholder">
                  <div style={{backgroundColor: 'black', borderRadius: 1000}}>
                    <div>
                      <NeuProWatchFace />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Abs-fill-parent Mip-amoled-clipPathR"
                style={{
                  clipPath: `polygon(100% 0%, ${clipPathZigZag}, 1000% 100%)`,
                }}>
                <div className="Watch-image Abs-fill-parent" />
                <div className="Abs-fill-parent Introduce-img-wf-placeholder">
                  <div style={{backgroundColor: 'black', borderRadius: 1000}}>
                    <div>
                      <NeuProWatchFace />
                    </div>
                    <div
                      className="Abs-fill-parent"
                      style={{
                        backgroundColor: 'black',
                        borderRadius: 1000,
                        opacity: 0.4,
                      }}
                    />
                  </div>
                </div>
              </div>
              <svg
                className="MipAmoledDelimiterOffset"
                height={'100%'}
                preserveAspectRatio="none"
                style={{
                  left: 0,
                  position: 'absolute',
                  top: 0,
                }}
                viewBox="0 0 100 100"
                width={'100%'}>
                <polyline
                  className="Mip-amoled-delimiter"
                  fill="none"
                  points={clipPathZigZag.replaceAll('%', '')}
                />
              </svg>
            </div>
          </div>
          <div className="section-text-cell">
            <p>
              Works beautifully on over <b>40 Garmin devices</b>, including both
              AMOLED and MIP displays.
            </p>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}
