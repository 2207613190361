import React, {ReactElement, useEffect, useRef, useState} from 'react';

import {ScrollTrigger} from 'gsap/ScrollTrigger';

import CustomDetails from '../components/CustomDetails';
import {MetaTimelinePoint, useMetaTimeline} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import {DataFieldType} from '../watch_face/DataFields';
import NeuProWatchFace from '../watch_face/NeuPro';
import './css/SectionDataFields.css';
import './css/SectionIntroduce.css';

type Props = {};

const SCALE_OUTER = 1.3;
const SCALE_H = 0.87;
const SCALE_V = 0.62;
const SCALE_PARTICLE = 1.4;

export default function SectionDataFields({}: Props): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: should depend on screen dimensions (width?)
  const DURATION_INTRO = 600;
  const DURATION_BETWEEN_DATAFIELDS = 700;
  const DURATION_OUTRO = 600;

  const DF_CHANGE_TIMEOUT = 30;

  type DataFieldLoc =
    | 'dataFieldLeft'
    | 'dataFieldTop'
    | 'dataFieldRight'
    | 'dataFieldBottom';

  const initialDataFields: {
    [k in DataFieldLoc]: DataFieldType;
  } = {
    dataFieldBottom: 'heart_rate',
    dataFieldLeft: 'distance_walked',
    dataFieldRight: 'recovery_time',
    dataFieldTop: 'current_temperature',
  };

  const [dataFields, setDataFields] = useState(initialDataFields);

  // TODO: Calculate these properly based on the watch layout
  const positions: {[k in DataFieldLoc]: {x: number; y: number}} = {
    dataFieldBottom: {x: 0, y: 28},
    dataFieldLeft: {x: -40, y: 0},
    dataFieldRight: {x: 40, y: 0},
    dataFieldTop: {x: 0, y: -28},
  };

  const dfUpdatesRaw: [DataFieldLoc, DataFieldType][] = [
    ['dataFieldLeft', 'steps'],
    ['dataFieldTop', 'weekly_run_distance'],
    ['dataFieldRight', 'notification_count'],
    ['dataFieldBottom', 'calories'],
  ];

  const N = dfUpdatesRaw.length;

  // TODO: refactor?
  const dfUpdates = dfUpdatesRaw.reduce(
    (p, c: [DataFieldLoc, DataFieldType]) => {
      const update: [DataFieldLoc, DataFieldType, DataFieldType] = [
        c[0],
        c[1],
        p.prevDFs[c[0]],
      ];
      return {
        prevDFs: {...initialDataFields, [c[0]]: c[1]},
        updates: [...p.updates, update],
      };
    },
    {
      prevDFs: initialDataFields,
      updates: [] as [DataFieldLoc, DataFieldType, DataFieldType][],
    },
  ).updates;

  const {hiddenDivs} = useMetaTimeline({
    // debug: true,
    points: dfUpdates.map(
      (dfUpd, i): MetaTimelinePoint => ({
        handler(dir) {
          const [dfName, dfValue, dfPrevValue] = dfUpd;
          setTimeout(
            () =>
              setDataFields(p => ({
                ...p,
                [dfName]: dir === 'forward' ? dfValue : dfPrevValue,
              })),
            DF_CHANGE_TIMEOUT,
          );
        },
        position: DURATION_INTRO + i * DURATION_BETWEEN_DATAFIELDS,
        setupTimeline(dir, tl) {
          const targetStyle = `.Data-fields-sparkles-${dir === 'forward' ? 'in' : 'out'}-${i}`;
          const ANIM_K = 1.6;
          const DURATION_SHOW_UP = 0.05 * ANIM_K;
          const DURATION_EXPAND = 0.2 * ANIM_K;
          const DURATION_FADE_OUT = 0.4 * ANIM_K;

          tl.fromTo(
            targetStyle,
            {
              opacity: 0,
              scale: 1,
            },
            {
              duration: DURATION_SHOW_UP,
              ease: 'none',
              opacity: 1,
              scale: 1.5,
            },
          );

          tl.to(targetStyle, {
            duration: DURATION_EXPAND,
            ease: 'none',
            scale: 5,
          });

          tl.to(targetStyle, {
            duration: DURATION_FADE_OUT,
            ease: 'power2.out',
            opacity: 0,
            scale: 8,
          });
        },
        toggleActionsBackward: 'reset none none play',
        toggleActionsForward: 'play none none reset',
      }),
    ),
    scope: containerRef,
    setupGlobalTimeline(tl) {
      const wfWidth =
        containerRef.current?.querySelector<HTMLElement>(
          '.Data-fields-img-wf-placeholder',
        )?.offsetWidth ?? 0;

      const markerWidth =
        containerRef.current?.querySelector<HTMLElement>('.flying-marker')
          ?.offsetWidth ?? 0;

      const w = wfWidth - markerWidth;
      const r = w / 2;

      tl.fromTo(
        '.flying-marker',
        {
          opacity: 0,
          scale: 0,
          x: w / 2,
          y: w / 2,
        },
        {
          duration: DURATION_INTRO / 2,
          ease: 'power1.inOut',
          opacity: 1,
          scale: 1,
        },
        0,
      );

      tl.to(
        '.flying-marker',
        {
          duration: DURATION_INTRO / 2,
          ease: 'power1.in',
          scale: SCALE_PARTICLE,
        },
        DURATION_INTRO / 2,
      );

      tl.to(
        '.flying-marker',
        {
          duration: DURATION_INTRO / 2,
          ease: 'power1.in',
          x: w / 2 - r,
        },
        DURATION_INTRO / 2,
      );

      tl.fromTo(
        '.flying-marker-parent',
        {rotate: 0},
        {
          duration: DURATION_INTRO / 2,
          ease: 'power1.in',
          rotate: 0,
          scale: SCALE_H,
        },
        DURATION_INTRO / 2,
      );

      for (let i = 0; i < N - 1; ++i) {
        tl.fromTo(
          '.flying-marker',
          {
            rotate: `${i * -90}deg`,
            x: w / 2 - r,
            y: w / 2,
          },
          {
            duration: DURATION_BETWEEN_DATAFIELDS,
            ease: 'power1.inOut',
            rotate: `${(i + 1) * -90}deg`,
          },
          DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * i,
        );

        tl.fromTo(
          '.flying-marker-parent',
          {rotate: `${i * 90}deg`},
          {
            duration: DURATION_BETWEEN_DATAFIELDS,
            ease: 'power1.inOut',
            rotate: `${(i + 1) * 90}deg`,
          },
          DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * i,
        );

        const scaleDataFieldRadiusNext = (i + 1) % 2 === 0 ? SCALE_H : SCALE_V;

        tl.to(
          '.flying-marker-parent',
          {
            duration: DURATION_BETWEEN_DATAFIELDS / 2,
            ease: 'power1.out',
            scale: SCALE_OUTER,
          },
          DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * i,
        );

        tl.to(
          '.flying-marker-parent',
          {
            duration: DURATION_BETWEEN_DATAFIELDS / 2,
            ease: 'power1.in',
            scale: scaleDataFieldRadiusNext,
          },
          DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * (i + 0.5),
        );

        tl.to(
          '.flying-marker',
          {
            duration: DURATION_BETWEEN_DATAFIELDS / 2,
            // ease: 'power4.out',
            ease: 'circ.out',
            scale: 1 / SCALE_OUTER,
          },
          DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * i,
        );

        tl.to(
          '.flying-marker',
          {
            duration: DURATION_BETWEEN_DATAFIELDS / 2,
            // ease: 'power4.in',
            ease: 'circ.in',
            opacity: 1,
            scale: SCALE_PARTICLE / scaleDataFieldRadiusNext,
          },
          DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * (i + 0.5),
        );
      }

      tl.to(
        '.flying-marker',
        {
          duration: DURATION_OUTRO / 2,
          ease: 'power1.out',
          x: w / 2,
          y: w / 2,
        },
        DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * (N - 1),
      );

      tl.to(
        '.flying-marker',
        {
          duration: DURATION_OUTRO / 2,
          ease: 'power1.out',
          scale: 1,
        },
        DURATION_INTRO + DURATION_BETWEEN_DATAFIELDS * (N - 1),
      );

      tl.to(
        '.flying-marker',
        {
          duration: DURATION_OUTRO / 2,
          ease: 'power2.inOut',
          opacity: 0,
          scale: 0,
        },
        DURATION_INTRO +
          DURATION_BETWEEN_DATAFIELDS * (N - 1) +
          DURATION_OUTRO / 2,
      );
    },
    totalDuration:
      DURATION_INTRO + (N - 1) * DURATION_BETWEEN_DATAFIELDS + DURATION_OUTRO,
    trigger: '.Data-fields-section',
  });

  const detailsRef = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    const el = detailsRef.current;
    el && el.addEventListener('toggle', () => ScrollTrigger.refresh());
  }, []);

  return (
    <div ref={containerRef} style={{gap: 0}}>
      {hiddenDivs}
      <ViewTracker sectionId="section_data_fields">
        <section
          className="Data-fields-section section-with-image"
          style={{marginTop: 0}}>
          <div className="section-title-cell">
            <h2>Focus on what matters</h2>
            <h3>with 25 data fields to choose from</h3>
          </div>
          <div className="section-image-cell">
            <div
              className="Watch-container section-image-clip"
              style={{position: 'relative'}}>
              <div className="Watch-image Abs-fill-parent" />
              <div className="Abs-fill-parent Data-fields-img-wf-placeholder">
                <NeuProWatchFace {...dataFields} />
                <div className="Abs-fill-parent flying-marker-parent Inline-size-container">
                  <div
                    className="flying-marker"
                    style={{left: 0, position: 'absolute', top: 0}}>
                    {[...Array(3)].map((_, i) => (
                      <div
                        className="flying-marker-particle"
                        key={`marker-${i}`}>
                        {[...Array(2)].map((_, j) => (
                          <div
                            className="inner Abs-fill-parent"
                            key={`marker-inner-${j}`}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                {[...Array(N)].flatMap((_x, i) => [
                  <div
                    className="Abs-fill-parent Inline-size-container"
                    key={`sparkles-in-parent-${i}`}
                    style={{aspectRatio: 1}}>
                    {[...Array(1)].map((_y, j) => (
                      <div
                        className={`Abs-fill-parent Data-fields-sparkles Data-fields-sparkles-in-${i} Data-fields-sparkles-${dfUpdates[i][1]}`}
                        key={`sparkles-in-${i}-${j}`}
                        style={{
                          left: `calc(${positions[dfUpdates[i][0]].x + 50}cqw - 6cqw)`,
                          top: `calc(${positions[dfUpdates[i][0]].y + 50}cqw - 6cqw)`,
                        }}
                      />
                    ))}
                  </div>,
                  <div
                    className="Abs-fill-parent Inline-size-container"
                    key={`sparkles-out-parent-${i}`}
                    style={{aspectRatio: 1}}>
                    {[...Array(1)].map((_y, j) => (
                      <div
                        className={`Abs-fill-parent Data-fields-sparkles Data-fields-sparkles-out-${i} Data-fields-sparkles-${dfUpdates[i][2]}`}
                        key={`sparkles-out-${i}-${j}`}
                        style={{
                          left: `calc(${positions[dfUpdates[i][0]].x + 50}cqw - 6cqw)`,
                          top: `calc(${positions[dfUpdates[i][0]].y + 50}cqw - 6cqw)`,
                        }}
                      />
                    ))}
                  </div>,
                ])}
              </div>
            </div>
          </div>
          <div className="section-text-cell">
            <p>
              Add up to <b>4 key data fields</b> out of <b>25 available</b>{' '}
              including steps, heart rate, and battery.
            </p>
            <CustomDetails title={'See all data fields'}>
              <ul className="expandable-list">
                <li>Month + month day</li>
                <li>Week day + month day</li>
                <li>Battery days</li>
                <li>Battery percentage</li>
                <li>Solar input</li>
                <li>Heart rate</li>
                <li>Steps</li>
                <li>Distance walked</li>
                <li>Calories</li>
                <li>Floors climbed</li>
                <li>Current temperature</li>
                <li>Min/max temperature</li>
                <li>Sunrise/sunset</li>
                <li>Altitude</li>
                <li>Notification count</li>
                <li>Calendar event</li>
                <li>Body battery</li>
                <li>Stress</li>
                <li>Intensity minutes</li>
                <li>Recovery time</li>
                <li>Weekly run distance</li>
                <li>Weekly bike distance</li>
                <li>VO2 max run</li>
                <li>VO2 max bike</li>
                <li>Pulse oxymeter</li>
              </ul>
            </CustomDetails>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}
