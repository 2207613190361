import React, {ReactElement, useContext, useMemo} from 'react';

import {getFunctions, httpsCallableFromURL} from '@firebase/functions';
import {ConnectIqVisit} from '@nimble-wings/ciq-website-common';
import Cookies from 'js-cookie';
import {isAndroid, isIOS} from 'react-device-detect';

import {logAnalyticsEvent} from '../utils/AnalyticsUtils';
import {getConnectIQLink} from '../utils/ConnectIQLink';
import {ConnectIqVisitContext} from '../utils/ConnectIqVisitContext';
import {ConsentContext} from '../utils/ConsentContext';
import {IntersectionContext} from '../utils/IntersectionContext';

type Props = {
  appId: string;
  elementName: string;
  title: string;
};

export default function OpenConnectIqButton({
  appId,
  elementName,
  title,
}: Props): ReactElement {
  const consentCtx = useContext(ConsentContext);
  const visitCtx = useContext(ConnectIqVisitContext);
  const intersectionCtx = useContext(IntersectionContext);

  const logConnectIqVisit = useMemo(() => {
    const functions = getFunctions();
    return httpsCallableFromURL(
      functions,
      'https://us-central1-connectiq-441119.cloudfunctions.net/app/log_connectiq_visit',
      {timeout: 500},
    );
  }, []);

  const navigateToConnectIq = async () => {
    logAnalyticsEvent('connect_iq_button_clicked');

    // Invoke Pixels before waiting for a response from Cloud Function
    if (consentCtx.adTrackingAllowed) {
      try {
        // @ts-ignore
        if (window.fbq) {
          // @ts-ignore
          const fbq = window.fbq;
          fbq('track', 'AddToCart');
        }
      } catch (e) {
        logAnalyticsEvent('track_add_to_cart_failed', {platform: 'meta'});
      }

      try {
        // @ts-ignore
        if (window.rdt) {
          // @ts-ignore
          const rdt = window.rdt;
          rdt('track', 'AddToCart');
        }
      } catch (e) {
        logAnalyticsEvent('track_add_to_cart_failed', {platform: 'reddit'});
      }
    }

    try {
      const now = new Date();
      const ciqVisit: ConnectIqVisit = {
        lastSeenSection: intersectionCtx.getLastVisitedSectionId(),
        locale: '',
        ms: now.getTime(),
        platform: isIOS ? 'ios' : isAndroid ? 'android' : 'desktop',
        tzOfs: now.getTimezoneOffset(),
        uiElement: elementName,
      };

      try {
        const searchParams = new URLSearchParams(window.location.search);
        const utm_campaign = searchParams.get('utm_campaign');
        if (utm_campaign) {
          ciqVisit.utm_campaign = utm_campaign;
        }
        const utm_source = searchParams.get('utm_source');
        if (utm_source) {
          ciqVisit.utm_source = utm_source;
        }
      } catch (e) {
        logAnalyticsEvent('init_connect_iq_visit_failed', {
          reason: 'utm_params',
        });
      }

      try {
        ciqVisit.locale = navigator.language;
      } catch (e) {
        logAnalyticsEvent('init_connect_iq_visit_failed', {
          reason: 'nav_locale',
        });
      }

      if (!consentCtx.adTrackingAllowed) {
        ciqVisit.anonymized = true;
      } else {
        try {
          const fbp = Cookies.get('_fbp');
          if (fbp !== undefined) {
            ciqVisit.fbp = fbp;
          }
          const fbc = Cookies.get('_fbc');
          if (fbc !== undefined) {
            ciqVisit.fbc = fbc;
          }
        } catch (e) {
          logAnalyticsEvent('init_connect_iq_visit_failed', {
            reason: 'fbp_fpc',
          });
        }

        try {
          // @ts-ignore
          if (window.rdt) {
            // @ts-ignore
            const rdt = window.rdt;
            if (rdt.uuid !== undefined) {
              ciqVisit.redditUuid = rdt.uuid;
            }
            if (rdt.clickId !== undefined) {
              ciqVisit.redditClid = rdt.clickId;
            }
          }
        } catch (e) {
          logAnalyticsEvent('init_connect_iq_visit_failed', {
            reason: 'reddit_uuid_cliid',
          });
        }
      }

      visitCtx.reportNavButtonClick();

      if (!visitCtx.hasBeenLogged) {
        // console.log(`Would log CIQ visit: ${JSON.stringify(ciqVisit, undefined, 2)}`);
        await logConnectIqVisit(ciqVisit);
      } else if (visitCtx.navButtonClickedTimes === 3) {
        logAnalyticsEvent('potential_error_in_connect_iq');
      }
    } catch (e) {
      let errCode = 'unknown';
      try {
        if (
          typeof e === 'object' &&
          e != null &&
          'code' in e &&
          typeof e.code === 'string'
        ) {
          errCode = e.code;
        }
      } catch (e2) {
        errCode = 'cannot_get_errcode';
        console.log("can't get error code");
      }

      logAnalyticsEvent(
        errCode === 'functions/deadline-exceeded'
          ? 'log_connect_iq_visit_timeout'
          : 'log_connect_iq_visit_failed',
        {code: errCode},
      );
    }

    // console.log('Would navigate');
    window.location.href = getConnectIQLink(appId);
  };

  return <button onClick={navigateToConnectIq}>{title}</button>;
}
