import React, {ReactElement, useRef} from 'react';

import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';

import {getReferenceDistance} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import NeuProWatchFace, {NeuProWatchFaceProps} from '../watch_face/NeuPro';
import './css/SectionStyle.css';

type Props = {};

export default function SectionStyle({}: Props): ReactElement {
  const containerRef = useRef(null);

  const zoom = [2.5, 3.5, 2.5, 3.5];

  useGSAP(
    () => {
      const photosInternal = gsap.utils.toArray<Element>(
        '.Photo-container-internal',
      );
      const photos = gsap.utils.toArray<Element>('.Photo-container');

      const scrollDistance = photos.length * getReferenceDistance();

      const headerHeight =
        document.querySelector<HTMLElement>('header')?.offsetHeight ?? 0;

      const scrollTrigger: ScrollTrigger.Vars = {
        anticipatePin: 1,
        end: `${scrollDistance}`,
        // markers: true,
        pin: true,
        scrub: 0.1,
        start: `+=0 +=${headerHeight}px`,
        trigger: '.Photo-gallery-section',
      };

      let tl = gsap.timeline({scrollTrigger});

      const TRANSITION_DURATION = 50;
      const ZOOM_DURATION = 50;
      const SINGLE_PHOTO_DURATION = TRANSITION_DURATION + ZOOM_DURATION;

      for (let i = 0; i < photos.length; ++i) {
        tl = tl.to(
          photosInternal[i],
          {
            duration: ZOOM_DURATION,
            ease: 'power2.inOut',
            scale: zoom[i] ?? 2.5,
          },
          SINGLE_PHOTO_DURATION * i,
        );
        if (i !== photos.length - 1) {
          tl = tl.to(
            '.Photo-gallery-internal',
            {
              duration: TRANSITION_DURATION,
              ease: 'power2.inOut',
              xPercent: (-100 * (i + 1)) / photos.length,
            },
            SINGLE_PHOTO_DURATION * i + ZOOM_DURATION,
          );
        }
      }
    },
    {scope: containerRef},
  );

  return (
    <div ref={containerRef}>
      <ViewTracker sectionId="section_style">
        <section className="Photo-gallery-section section-with-image">
          <div className="section-title-cell">
            <h2>Thoughtfully designed</h2>
            <h3>Style that fits everyone</h3>
          </div>
          <div className="section-image-cell Inline-size-container">
            <div className="section-image-clip Photo-gallery ">
              <div className="Photo-gallery-internal">
                <PhotoItem
                  dataFieldBottom="heart_rate"
                  dataFieldLeft="notification_count"
                  dataFieldRight="weekly_bike_distance"
                  dataFieldTop="calendar_event"
                  hoursOverride={10}
                  minutesOverride={32}
                />
                <PhotoItem
                  dataFieldBottom="heart_rate"
                  dataFieldLeft="steps"
                  dataFieldRight="vo2_max_bike"
                  dataFieldTop="current_temperature"
                  hoursOverride={16}
                  minutesOverride={8}
                />
                {/* <PhotoItem
                  dataFieldBottom="sunrise_sunset"
                  dataFieldLeft="floors_climbed"
                  dataFieldRight="heart_rate"
                  dataFieldTop="weekly_run_distance"
                  hoursOverride={14}
                  minutesOverride={29}
                />
                <PhotoItem
                  dataFieldBottom="heart_rate"
                  dataFieldLeft="intensity_minutes"
                  dataFieldRight="recovery_time"
                  dataFieldTop="stress"
                  hoursOverride={18}
                  minutesOverride={41}
                /> */}
              </div>
            </div>
          </div>
          <div className="section-text-cell">
            <ul>
              <li>
                A clean, modern design with <b>attention to detail</b>
              </li>
              <li>
                <b>Typography</b> optimized for clarity and ease of use
              </li>
              <li>
                Thoroughly tested to ensure <b>data fields never overlap</b>
              </li>
              <li>
                <b>Great contrast and readability</b>, even on MIP displays
              </li>
            </ul>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}

type PhotoItemProps = NeuProWatchFaceProps;

function PhotoItem(props: PhotoItemProps): ReactElement {
  const {...wfProps} = props;

  // TODO: use style={{mixBlendMode: 'screen'}} to render the glare

  // The image urls and the watch face transforms are specified in CSS
  return (
    <div className="Photo-container">
      <div className="Photo-container-internal">
        <div className="Abs-fill-parent Watch-face-placeholder">
          <NeuProWatchFace {...wfProps} />
        </div>
        <img className="Photo-image" />
      </div>
    </div>
  );
}
