import {useContext, useEffect, useRef} from 'react';

import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
  setConsent,
} from '@firebase/analytics';

import {ConsentContext} from './ConsentContext';

export default function useConsentHandler() {
  const consentCtx = useContext(ConsentContext);

  const fbConsentSettingRef = useRef(false);
  const rdtInitialisedRef = useRef(false);

  useEffect(() => {
    /**
     * Links:
     * - https://stackoverflow.com/questions/71453414/how-to-configure-firebase-for-consent-and-data-compliance-on-web
     * - https://firebase.google.com/docs/reference/js/analytics.consentsettings.md#consentsettings_interface
     * - https://github.com/firebase/firebase-js-sdk/discussions/6272
     * - https://firebase.google.com/docs/reference/js/analytics.md#setanalyticscollectionenabled_494179c
     */

    setConsent({
      /** Sets consent for personalized advertising. */
      ad_personalization: firebaseConsentStr(consentCtx.adTrackingAllowed),

      /** Enables storage, such as cookies, related to advertising */
      ad_storage: firebaseConsentStr(consentCtx.adTrackingAllowed),

      /** Sets consent for sending user data to Google for advertising purposes. */
      ad_user_data: firebaseConsentStr(consentCtx.adTrackingAllowed),

      /** Enables storage, such as cookies, related to analytics (for example, visit duration) */
      analytics_storage: firebaseConsentStr(consentCtx.analyticsAllowed),

      /**
       * Enables storage that supports the functionality of the website or app such as language settings
       */
      functionality_storage: firebaseConsentStr(false),
      // functionality_storage: firebaseConsentStr(true),

      /** Enables storage related to personalization such as video recommendations */
      personalization_storage: firebaseConsentStr(false),

      /**
       * Enables storage related to security such as authentication functionality, fraud prevention,
       * and other user protection.
       */
      security_storage: firebaseConsentStr(false),
    });

    setAnalyticsCollectionEnabled(getAnalytics(), consentCtx.analyticsAllowed);

    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      const fbq = window.fbq;
      if (consentCtx.adTrackingAllowed !== fbConsentSettingRef.current) {
        fbq('consent', consentCtx.adTrackingAllowed ? 'grant' : 'revoke');

        // For some reason, Meta Pixel doesn't report some events when access
        // has been granted if beforehands it was called with "reject" repeatedly,
        // so I'm only calling it when consent settings actually change
        fbConsentSettingRef.current = consentCtx.adTrackingAllowed;
      }
    }

    // @ts-ignore
    if (window.rdt) {
      // @ts-ignore
      const rdt = window.rdt;
      if (!rdtInitialisedRef.current && consentCtx.adTrackingAllowed) {
        rdt('init', 'a2_fkxof39zpg71');
        rdtInitialisedRef.current = true;
        rdt('track', 'PageVisit');
      }
    }
  }, [consentCtx]);
}

function firebaseConsentStr(b: boolean) {
  return b ? 'granted' : 'denied';
}
